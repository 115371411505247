import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import { BgColorsOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ColorPicker from 'common/ColorPicker/ColorPicker';

const ColorPickerPopover = ({ color, onChangeColor }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [selectedColor, setSelectedColor] = useState(color); 

  const visibleChange = (isVisible) => setVisible(isVisible);

  const handleSubmitColor = () => {
    onChangeColor(selectedColor);  
    setVisible(false);  
  };

  const content = (
    <div style={{ padding: '8px' }}>
      <ColorPicker color={selectedColor} onChange={setSelectedColor} />
      <Button
        className="sales__price-template__add-template"
        style={{ position: 'static', display: 'flex', alignItems: 'center', margin: '10px auto 0' }}
        onClick={handleSubmitColor} 
      >
        <PlusOutlined className="sales__price-template__add-template__icon" />
        <span className="sales__price-template__add-template__text">
          {t('modules.markup_side_menu.input.edit_color')}
        </span>
      </Button>
    </div>
  );

  return (
    <div className="BgColors" style={{ display: 'inline-block', cursor: 'pointer', position: 'relative' }}>
      <Popover
        content={content}
        trigger="click"
        visible={visible}
        onVisibleChange={visibleChange}
        overlayStyle={{ zIndex: 1100 }}
      >
        <Button icon={<BgColorsOutlined />} style={{ border: 'none', background: 'rgba(255, 255, 255, 0.2)' }} />
      </Popover>
    </div>
  );
};

export default ColorPickerPopover;

