import React, { useState } from 'react';
import { isEmpty } from 'ramda';
import { CloseCircleOutlined, LeftOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal, Button, message, Tooltip } from 'antd';
import { UPDATE_PRICE_TEMPLATE, UPDATE_PRICES, ADD_PLACES_TEMPLATE } from '../../graphql';
import { useTranslation } from 'react-i18next';
import {
  formatPrice,
  generatePriceTemplateErrors,
  isValidPrices, pricesSort,
  updatePriceArray
} from '../../utils/priceTemplateHelpers';
import PriceCategoryItem from './PriceCategoryItem';

const PriceEditMenu = (props) => {
  const { t } = useTranslation();
  const [stateProps, setState] = useState({
    newTitle: '',
    newPrices: [],
    hints: new Map(),
  });
  const addNewPrices = async (newPrices) => {
    const pricesList = newPrices.map(price => ({
      value: price.value + '.00',
      priceCategoryId: price.id,
    }));
  
    return await props.client.query({
      query: ADD_PLACES_TEMPLATE,
      variables: {
        id: props.selectedPriceTemplate.id,
        prices: {
          list: pricesList,
        },
      },
      fetchPolicy: 'no-cache',
    });
  };
  const updatePrices = async () => {
    for (const item of stateProps.newPrices) {
      await props.client.query({
        query: UPDATE_PRICES,
        variables: {
          value: item.value + '.00',
          priceTemplateId: props.selectedPriceTemplate.id,
          priceCategoryId: item.id,
        },
        fetchPolicy: 'no-cache',
      });
    }
  };

  const updateTemplate = async () => {
    try {
      if (stateProps.newTitle) {
        await props.client.query({
          query: UPDATE_PRICE_TEMPLATE,
          variables: {
            id: props.selectedPriceTemplate.id,
            data: {
              title: stateProps.newTitle,
            },
          },
          fetchPolicy: 'no-cache',
        });
      }

      if (!isEmpty(stateProps.newPrices) && isValidPrices(stateProps.newPrices)) {
        const { currentPrices, newPrices } = stateProps.newPrices.reduce((acc, item) => {
          (props.selectedPriceTemplate.prices?.some(price => price.id === item.id)) 
          ? acc.currentPrices.push(item)
          : acc.newPrices.push(item);
          return acc;
        }, { currentPrices: [], newPrices: [] });
  
        if (currentPrices.length > 0) await updatePrices();
        if (newPrices.length > 0) await addNewPrices(newPrices);
        message.success(t('modules.price_edit_menu.message_success'));
        props.setLoading();
      } else {
        setState({
          ...stateProps,
          hints: generatePriceTemplateErrors(stateProps.newPrices, t('modules.price_edit_menu.message_error.invalid_price')),
        })
        message.error(t('modules.price_edit_menu.message_error.invalid_price'));
      }
    } catch (e) {
      console.error(e);
      message.error(t('modules.price_edit_menu.message_error.update_error'));
    }
  };

  const updateState = (props) => {
    setState(prevState => {
      return {
        ...prevState,
        ...props,
      }
    })
  }
  const onChangePrice = (e, item) => {
    e.preventDefault();
    const newPrices = updatePriceArray(stateProps.newPrices, e.target.value, item);
    updateState({newPrices: newPrices});
  }

  const onBlurPrice = (e, item) => {
    e.preventDefault();
    if (e.target.value) {
      const newValue = formatPrice(e.target.value);
      const newPrices = updatePriceArray(stateProps.newPrices, newValue, item);
      const errors = generatePriceTemplateErrors(newPrices, t('modules.price_edit_menu.message_error.invalid_price'));
      updateState({
        newPrices: newPrices,
        hints: errors,
      });
    }
  }

  const renderPriceCategories = () => {
    const prices = props.selectedPriceTemplate.prices || [];
    const priceCategories = props.selectedPriceTemplate.priceCategoryTemplate?.priceCategories || [];
    const allPoints = [...prices, ...priceCategories]

    const allColors = allPoints.reduce((acc, item) => {
      const isRepeat = acc.some(existingItem => existingItem.id === item.id) 
      if (!isRepeat) acc.push(item)
      return acc;
    }, [])

    return allColors
      .sort(pricesSort)
      .map((item) => (
        <PriceCategoryItem
          price={item}
          hint={stateProps.hints.get(item.id)}
          value={stateProps.newPrices.find(price => price.id === item.id)?.value}
          onChange={onChangePrice}
          onBlur={onBlurPrice}
        />
      )
    )
  }

  return (
    <div className="sales__price-template__side-menu__container">
      <div className="sales__tickets__side-menu__header">
        <LeftOutlined
          onClick={() => {
            props.onClose();
          }}
          className="sales__tickets__side-menu__header__icon"
        />
        <div className="sales__tickets__side-menu__header__action">{t('modules.price_edit_menu.div.editing')}</div>
        {isEmpty(stateProps.newPrices) && !stateProps.newTitle ? (
          // <div
          //   className="sales__price-template__side-menu__header__delete"
          //   onClick={ev => {
          //     setState({
          //       ...stateProps,
          //       isAcceptDeleteTemplate: true
          //     });
          //   }}
          // >
          //   {t('modules.price_edit_menu.div.delete')}
          // </div>
          ''
        ) : (
          <div
            className="sales__price-template__side-menu__header__update"
            onClick={async () => {
              await updateTemplate();
            }}
          >
            {t('modules.price_edit_menu.div.save')}
          </div>
        )}
      </div>
      <div>
        <div className="sales__tickets__side-menu__subtitle__pd sales__tickets__side-menu__subtitle">
          {t('modules.price_edit_menu.div.template_name')}
        </div>
        <input
          className="sales__price-template__side-menu__body__title-input"
          defaultValue={props.selectedPriceTemplate.title}
          onChange={(ev) => {
            setState({
              ...stateProps,
              newTitle: ev.target.value,
            });
          }}
        />
        <div className="sales__price-template__side-menu__body__container">
          {renderPriceCategories()}
        </div>
      </div>
      <Modal
        visible={stateProps.isAcceptDeleteTemplate}
        centered
        closable={false}
        onCancel={() => {
          setState({
            ...stateProps,
            isAcceptDeleteTemplate: false,
          });
        }}
        width={415}
        footer={null}
      >
        <>
          <div className="sales__tickets__list-table__accept-modal__text-container">
            <CloseCircleOutlined className="sales__tickets__list-table__accept-modal__icon" />
            <div className="sales__tickets__list-table__accept-modal__text">{t('modules.price_edit_menu.div.confirmation_message')}</div>
          </div>
          <div className="sales__tickets__list-table__accept-modal__button-container">
            <Button
              id="PriceEditMenuCancelButton"
              className="sales__tickets__list-table__accept-modal__decline-button"
              onClick={() => {
                setState({
                  ...stateProps,
                  isAcceptDeleteTemplate: false,
                });
              }}
            >
              {t('modules.price_edit_menu.button.cancel')}
            </Button>
            <Button
              id="PriceEditMenuDeleteButton"
              className="sales__tickets__list-table__accept-modal__accept-button"
              onClick={() => {
                setState({
                  ...stateProps,
                  isAcceptDeleteTemplate: false,
                });
                // deleteTemplate()
                message.error(t('modules.price_edit_menu.message_error.removing_impossible'));
              }}
            >
              {stateProps.isRefundProcessing ? <LoadingOutlined /> : t('modules.price_edit_menu.button.delete')}
            </Button>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default PriceEditMenu;
