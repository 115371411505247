import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './EditMark.css';
import ColorPicker from 'common/ColorPicker/ColorPicker';

const EditMarkButton = ({ addNewColor, newColor, setNewColor }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [selectedColor, setSelectedColor] = useState(newColor);

  const handleColorChange = (color) => {
    setSelectedColor(color); 
    setNewColor(color);
  };

  const handleColorSubmit = () => {
    addNewColor(selectedColor);
    setVisible(false);
  };

  const content = (
    <div style={{ padding: '8px' }}>
      <ColorPicker color={selectedColor} onChange={handleColorChange} />
      <Button
        className="sales__price-template__add-template"
        style={{ position: 'static', display: 'flex', alignItems: 'center', margin: '10px auto 0' }}
        onClick={handleColorSubmit}
      >
        <span className="sales__price-template__add-template__text">
          {t('modules.markup_side_menu.input.exception_edit')}
        </span>
      </Button>
    </div>
  );

  return (
    <div className="stadium__markup__side-menu__title-edit">
      <Popover
        content={content}
        trigger="click"
        visible={visible}
        onVisibleChange={setVisible}
        overlayStyle={{ zIndex: 1100 }}
      >
        <Button className="sales__price-template__add-template" style={{ position: 'static' }}>
          <PlusOutlined className="sales__price-template__add-template__icon" />
          <span className="sales__price-template__add-template__text">
            {t('modules.markup_side_menu.input.edit_title')}
          </span>
        </Button>
      </Popover>
    </div>
  );
};

export default EditMarkButton;
