import React from 'react';
import { ChromePicker } from 'react-color';

const ColorPicker = React.memo(({ color, onChange }) => {
  const handleChangeComplete = (color) => onChange(color.hex);

  return <ChromePicker color={color} onChangeComplete={handleChangeComplete} />;
});

export default ColorPicker;
