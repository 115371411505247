import React, { useState } from 'react';
import './EditMark.css';
import ColorPickerPopover from 'common/PopoverPicker/ColorPickerPopover';

const EditColorMark = ({ color, onChangeColor }) => {
  const [tempColor, setTempColor] = useState(color);

  const colorChange = (newColor) => {
    setTempColor(newColor);
    onChangeColor(newColor);
  };

  return <ColorPickerPopover color={tempColor} onChangeColor={colorChange} />;
};

export default EditColorMark;
